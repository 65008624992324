import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { MainState } from './state';

const defaultState: MainState = {
  isLoggedIn: null,
  token: '',
  logInError: false,
  logInErrorMessage: '',
  userProfile: null,
  dashboardMiniDrawer: false,
  dashboardShowDrawer: true,
  notifications: [],
  files: [],
  projects: [],
  file: null,
  project: null,
  mqttLogs: [],
  mqttLog: null,
  groups: [],
  group: null,
  scenes: [],
  devices: [],
  device: null,
  assignedGroups: [],
  qrUser: null,
  scene: null,
  events: [],
  event: null,
  handlers: [],
  handler: null,
  buildings: [],
  building: null,
  floorArea: null,
  floorAreas: [],
};

export const mainModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
