export const getLocalToken = () => localStorage.getItem('token');

export const saveLocalToken = (token: string) => localStorage.setItem('token', token);

export const removeLocalToken = () => localStorage.removeItem('token');

export const USER_ROLES = {
    'user': 'Guest',
    'admin': 'Admin',
    'api': 'Api',
    'staff': 'Staff',
    undefined: '',
    null: '',
  };

export const MAP_PROJECT_ACTION = [
  {
    'key': 'update',
    'value': 'Force Update'
  },
  {
    'key': 'restart',
    'value': 'Restart local devices'
  },
  {
    'key': 'reinstall',
    'value': 'Reinstall'
  },
  {
    'key': 'reboot',
    'value': 'Reboot'
  },
];


export const MAP_PROJECT_MODES = [
  {
    'key':'normal',
    'value': 'Normal'
  },
  {
    'key':'debug',
    'value': 'Debug'
  },
  {
    'key':'configuration',
    'value': 'Configuration'
  },
];


export const parseDate = (date: string) => {
    let dt = new Date(date);
    return `${
        dt.getFullYear().toString().padStart(4, '0')}-${
        (dt.getMonth()+1).toString().padStart(2, '0')}-${
        dt.getDate().toString().padStart(2, '0')} ${
        dt.getHours().toString().padStart(2, '0')}:${
        dt.getMinutes().toString().padStart(2, '0')}:${
        dt.getSeconds().toString().padStart(2, '0')
    }`;
};