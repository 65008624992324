import { IUserProfile, IFileData, IProjectData, IMqttLogData, IGroupData, ISceneData, IDeviceData, IEventData, IHandlerData, IBuildingData, IFloorplanAreaData } from '@/interfaces';
import { MainState, AppNotification } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';


export const mutations = {
    setToken(state: MainState, payload: string) {
        state.token = payload;
    },
    setLoggedIn(state: MainState, payload: boolean) {
        state.isLoggedIn = payload;
    },
    setLogInError(state: MainState, payload: boolean) {
        state.logInError = payload;
    },
    setLogInErrorMessage(state: MainState, payload: string) {
        state.logInErrorMessage = payload;
    },
    setUserProfile(state: MainState, payload: IUserProfile) {
        state.userProfile = payload;
    },
    setDashboardMiniDrawer(state: MainState, payload: boolean) {
        state.dashboardMiniDrawer = payload;
    },
    setDashboardShowDrawer(state: MainState, payload: boolean) {
        state.dashboardShowDrawer = payload;
    },
    addNotification(state: MainState, payload: AppNotification) {
        state.notifications.push(payload);
    },
    removeNotification(state: MainState, payload: AppNotification) {
        state.notifications = state.notifications.filter((notification) => notification !== payload);
    },
    setUploadFiles(state: MainState, payload: IFileData[]) {
        state.files = payload;
    },
    setLogs(state: MainState, payload: IMqttLogData[]) {
        state.mqttLogs = payload;
    },
    setLog(state: MainState, payload: IMqttLogData) {
        state.mqttLog = payload;
    },
    setProjects(state: MainState, payload: IProjectData[]) {
        state.projects = payload;
    },
    setProject(state: MainState, payload: IProjectData) {
        state.project = payload;
    },
    setFile(state: MainState, payload: IFileData) {
        state.file = payload;
    },
    setGroups(state: MainState, payload: IGroupData[]) {
        state.groups = payload;
    },
    setGroup(state: MainState, payload: IGroupData) {
        state.group = payload;
    },
    setScenes(state: MainState, payload: ISceneData[]) {
        state.scenes = payload;
    },
    setScene(state: MainState, payload: ISceneData) {
        state.scene = payload;
    },
    setDevices(state: MainState, payload: []) {
        state.devices = payload;
    },
    setDevice(state: MainState, payload: IDeviceData) {
        state.device = payload;
    },
    setAssignedGroups(state: MainState, payload: []) {
        state.assignedGroups = payload;
    },
    setQrUser(state: MainState, payload: IUserProfile) {
        state.qrUser = payload;
    },
    setEvents(state: MainState, payload: IEventData[]) {
        state.events = payload;
    },
    setEvent(state: MainState, payload: IEventData) {
        state.event = payload;
    },
    setHandlers(state: MainState, payload: IHandlerData[]) {
        state.handlers = payload;
    },
    setHandler(state: MainState, payload: IHandlerData) {
        state.handler = payload;
    },
    setBuildings(state: MainState, payload: IBuildingData[]) {
        state.buildings = payload;
    },
    setBuilding(state: MainState, payload: IBuildingData) {
        state.building = payload;
    },
    setFloorArea(state: MainState, payload: IFloorplanAreaData) {
        state.floorArea = payload;
    },
    setFloorAreas(state: MainState, payload: IFloorplanAreaData[]) {
        state.floorAreas = payload;
    },
};

const {commit} = getStoreAccessors<MainState | any, State>('');

export const commitSetDashboardMiniDrawer = commit(mutations.setDashboardMiniDrawer);
export const commitSetDashboardShowDrawer = commit(mutations.setDashboardShowDrawer);
export const commitSetLoggedIn = commit(mutations.setLoggedIn);
export const commitSetLogInError = commit(mutations.setLogInError);
export const commitSetLogInErrorMessage = commit(mutations.setLogInErrorMessage);
export const commitSetToken = commit(mutations.setToken);
export const commitSetUserProfile = commit(mutations.setUserProfile);
export const commitAddNotification = commit(mutations.addNotification);
export const commitRemoveNotification = commit(mutations.removeNotification);
export const commitSetUploadFiles = commit(mutations.setUploadFiles);
export const commitSetLogs = commit(mutations.setLogs);
export const commitSetLog = commit(mutations.setLog);
export const commitSetProjects = commit(mutations.setProjects);
export const commitSetProject = commit(mutations.setProject);
export const commitSetFile = commit(mutations.setFile);
export const commitSetGroups = commit(mutations.setGroups);
export const commitSetGroup = commit(mutations.setGroup);
export const commitSetScenes = commit(mutations.setScenes);
export const commitSetScene = commit(mutations.setScene);
export const commitSetDevices = commit(mutations.setDevices);
export const commitSetDevice = commit(mutations.setDevice);
export const commitSetAssignedGroups = commit(mutations.setAssignedGroups);
export const commitSetQrUser = commit(mutations.setQrUser);
export const commitSetEvents = commit(mutations.setEvents);
export const commitSetEvent = commit(mutations.setEvent);
export const commitSetHandlers = commit(mutations.setHandlers);
export const commitSetHandler = commit(mutations.setHandler);
export const commitSetBuildings = commit(mutations.setBuildings);
export const commitSetBuilding = commit(mutations.setBuilding);
export const commitSetFloorArea = commit(mutations.setFloorArea);
export const commitSetFloorAreas = commit(mutations.setFloorAreas);
