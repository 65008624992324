import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'recover-password',
          component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        },
        {
          path: 'reset-password',
          component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'),
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                    /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                    /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                    /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/CreateUser.vue'),
                },
              ],
            },
            {
              path: 'files',
              component: RouterComponent,
              children: [
                {
                  path: ':id/edit',
                  name: 'main-files-edit',
                  component: () => import(
                    /* webpackChunkName: "main-files-edit" */ './views/main/file/File.vue'),
                },
              ],
            },
            {
              path: 'buildings',
              component: RouterComponent,
              children: [
                {
                  path: ':id/edit',
                  name: 'main-buildings-edit',
                  component: () => import(
                    /* webpackChunkName: "main-files-edit" */ './views/main/building/Edit.vue'),
                },
              ],
            },
            {
              path: 'projects',
              component: RouterComponent,
              children: [
                {
                  path: 'all', /* edit */
                  component: () => import('./views/main/project/Projects.vue'),
                },
                {
                  path: 'create',
                  name: 'main-projects-create',
                  component: () => import('./views/main/project/Create.vue'),
                },
                {
                  path: ':id/edit',
                  name: 'main-projects-edit',
                  component: () => import('./views/main/project/Edit.vue'),
                },
                {
                  path: ':id/detail',
                  name: 'main-projects-detail',
                  component: () => import('./views/main/project/Detail.vue'),
                },
                {
                  path: ':id/building',
                  name: 'main-buildings-create',
                  component: () => import('./views/main/building/Create.vue'),
                },
                {
                  path: ':id/assign-user',
                  name: 'main-projects-assign',
                  component: () => import('./views/main/project/Assign.vue'),
                },
                {
                  path: ':id/unassign-user',
                  name: 'main-projects-unassign',
                  component: () => import('./views/main/project/Unassign.vue'),
                },
                {
                  path: ':id/event',
                  name: 'main-events-create',
                  component: () => import('./views/main/event/Create.vue'),
                },
                {
                  path: ':id/actions',
                  name: 'main-projects-action',
                  component: () => import('./views/main/project/Action.vue'),
                },
                {
                  path: ':projectId/qr-code',
                  name: 'qr-code-create',
                  component: () => import('./views/main/qr/Create.vue'),
                },
                {
                  path: ':projectId/qr-code/:userId',
                  name: 'qr-code-edit',
                  component: () => import('./views/main/qr/Edit.vue'),
                },
                {
                  path: ':id/assign-groups/:user',
                  name: 'main-groups-assign',
                  component: () => import('./views/main/group/Assign.vue'),
                },
                {
                  path: ':id/unassign-groups/:user',
                  name: 'main-groups-unassign',
                  component: () => import('./views/main/group/Assign.vue'),
                },
                {
                  path: ':id/upload',
                  name: 'main-projects-fileupload',
                  component: () => import('./views/main/file/Upload.vue'),
                },
                {
                  path: ':id/create-group',
                  name: 'main-groups-create',
                  component: () => import('./views/main/group/Create.vue'),
                },
              ],
            },
            {
              path: 'mqtt_logs',
              component: RouterComponent,
              redirect: 'mqtt_logs/all',
              children: [
                {
                  path: 'all', /* edit */
                  component: () => import('./views/main/mqtt/Logs.vue'),
                },
                {
                  path: ':id/detail',
                  name: 'main-mqtt-detail',
                  component: () => import('./views/main/mqtt/Detail.vue'),
                },
              ],
            },
            {
              path: 'scenes',
              component: RouterComponent,
              children: [
                {
                  path: ':id/edit',
                  name: 'main-scenes-edit',
                  component: () => import('./views/main/scene/Edit.vue'),
                },
              ],
            },
            {
              path: 'groups',
              component: RouterComponent,
              children: [
                {
                  path: ':id/edit',
                  name: 'main-groups-edit',
                  component: () => import('./views/main/group/Edit.vue'),
                },
                {
                  path: ':id/add-scene',
                  name: 'main-scenes-create',
                  component: () => import('./views/main/scene/Create.vue'),
                },
              ],
            },
            {
              path: 'devices',
              component: RouterComponent,
              children: [
                {
                  path: ':id/edit',
                  name: 'main-devices-edit',
                  component: () => import('./views/main/device/Edit.vue'),
                },
              ],
            },
            {
              path: 'events',
              component: RouterComponent,
              children: [
                {
                  path: ':id/edit',
                  name: 'main-events-edit',
                  component: () => import('./views/main/event/Edit.vue'),
                },
                {
                  path: ':id/add-handler',
                  name: 'main-handlers-create',
                  component: () => import('./views/main/handler/Create.vue'),
                },
              ],
            },
            {
              path: 'handlers',
              component: RouterComponent,
              children: [
                {
                  path: ':id/edit',
                  name: 'main-handlers-edit',
                  component: () => import('./views/main/handler/Edit.vue'),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/floorplan/:id',
      name: 'floor-sample',
      component: () => import(
        /* webpackChunkName: "main-files-edit" */ './views/main/FloorplanSample.vue'),
    },
    {
      path: '/test-cross',
      component: () => import(/* webpackChunkName: "test-cross" */ './views/TestCross.vue'),
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
