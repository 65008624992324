import { api } from '@/api';
import {
    IFileDataUpdate,
    IGroupCreate,
    IProjectCreate,
    IGroupUpdate,
    IDeviceUpdate,
    IQrUserCreate,
    IQrUserUpdate,
    ISceneCreate,
    ISceneUpdate,
    IEventCreate,
    IHandlerCreate,
    IEventUpdate,
    IHandlerUpdate,
    IBuildingCreate,
    IFloorplanAreaUpdate,
    IFloorplanAreaCreate,
} from '@/interfaces';
import router from '@/router';
import { getLocalToken, removeLocalToken, saveLocalToken } from '@/utils';
import { AxiosError } from 'axios';
import { getStoreAccessors } from 'typesafe-vuex';
import { ActionContext } from 'vuex';
import { State } from '../state';
import {
    commitAddNotification,
    commitRemoveNotification,
    commitSetLoggedIn,
    commitSetLogInError,
    commitSetToken,
    commitSetUserProfile,
    commitSetUploadFiles,
    commitSetFile,
    commitSetProjects,
    commitSetProject,
    commitSetLogs,
    commitSetLog,
    commitSetGroups,
    commitSetGroup,
    commitSetLogInErrorMessage,
    commitSetScenes,
    commitSetScene,
    commitSetDevices,
    commitSetDevice,
    commitSetAssignedGroups,
    commitSetQrUser,
    commitSetEvents,
    commitSetEvent,
    commitSetHandlers,
    commitSetHandler,
    commitSetBuildings,
    commitSetBuilding,
    commitSetFloorAreas,
} from './mutations';
import { AppNotification, MainState } from './state';

type MainContext = ActionContext<MainState, State>;

export const actions = {
    async actionLogInSample(context: MainContext, payload: { username: string; password: string }) {
        const response = await api.logInGetToken(payload.username, payload.password);
        const token = response.data.access_token;
        if (token) {
            commitSetToken(context, token);
            commitSetLoggedIn(context, true);
            commitSetLogInError(context, false);
            commitSetLogInErrorMessage(context, '');
        }
    },

    async actionLogIn(context: MainContext, payload: { username: string; password: string }) {
        try {
            const response = await api.logInGetToken(payload.username, payload.password);
            const token = response.data.access_token;
            if (token) {
                saveLocalToken(token);
                commitSetToken(context, token);
                commitSetLoggedIn(context, true);
                commitSetLogInError(context, false);
                commitSetLogInErrorMessage(context, '');
                await dispatchGetUserProfile(context);
                await dispatchRouteLoggedIn(context);
                commitAddNotification(context, { content: 'Logged in', color: 'success' });
            } else {
                await dispatchLogOut(context);
            }
        } catch (err) {
            if (err.response && err.response.data) {
                commitSetLogInErrorMessage(context, err.response.data.detail);
            }
            commitSetLogInError(context, true);
            await dispatchLogOut(context);
        }
    },
    async actionGetUserProfile(context: MainContext) {
        try {
            const response = await api.getMe(context.state.token);
            if (response.data) {
                if (!(response.data.role && response.data.role === 'admin')) {
                    throw new Error('Your account doesn\'t have permission to login here.');
                }
                commitSetUserProfile(context, response.data);
            }
        } catch (error) {
            commitSetLogInErrorMessage(context, error as string);
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateUserProfile(context: MainContext, payload) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateMe(context.state.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUserProfile(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Profile successfully updated', color: 'success' });
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionCheckLoggedIn(context: MainContext) {
        if (!context.state.isLoggedIn) {
            let token = context.state.token;
            if (!token) {
                const localToken = getLocalToken();
                if (localToken) {
                    commitSetToken(context, localToken);
                    token = localToken;
                }
            }
            if (token) {
                try {
                    const response = await api.getMe(token);
                    commitSetLoggedIn(context, true);
                    commitSetUserProfile(context, response.data);
                } catch (error) {
                    await dispatchRemoveLogIn(context);
                }
            } else {
                await dispatchRemoveLogIn(context);
            }
        }
    },
    async actionRemoveLogIn(context: MainContext) {
        removeLocalToken();
        commitSetToken(context, '');
        commitSetLoggedIn(context, false);
    },
    async actionLogOut(context: MainContext) {
        await dispatchRemoveLogIn(context);
        await dispatchRouteLogOut(context);
    },
    async actionUserLogOut(context: MainContext) {
        await dispatchLogOut(context);
        commitAddNotification(context, { content: 'Logged out', color: 'success' });
    },
    actionRouteLogOut(context: MainContext) {
        if (router.currentRoute.path !== '/login') {
            router.push('/login');
        }
    },
    async actionCheckApiError(context: MainContext, payload: AxiosError) {
        if (payload.response!.status === 401) {
            await dispatchLogOut(context);
        }
    },
    actionRouteLoggedIn(context: MainContext) {
        if (router.currentRoute.path === '/login' || router.currentRoute.path === '/') {
            router.push('/main');
        }
    },
    async removeNotification(context: MainContext, payload: { notification: AppNotification, timeout: number }) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                commitRemoveNotification(context, payload.notification);
                resolve(true);
            }, payload.timeout);
        });
    },
    async passwordRecovery(context: MainContext, payload: { username: string }) {
        const loadingNotification = { content: 'Sending password recovery email', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.passwordRecovery(payload.username),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Password recovery email sent', color: 'success' });
            await dispatchLogOut(context);
        } catch (error) {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Incorrect username' });
        }
    },
    async resetPassword(context: MainContext, payload: { password: string, token: string }) {
        const loadingNotification = { content: 'Resetting password', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.resetPassword(payload.password, payload.token),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Password successfully reset', color: 'success' });
            await dispatchLogOut(context);
        } catch {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { color: 'error', content: 'Error resetting password' });
        }
    },
    // mqtt log
    async getMqttLogs(context: MainContext) {
        try {
            const loadingNotification = { content: 'Loading logs', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.getMqttLogs(context.rootState.main.token);
            commitRemoveNotification(context, loadingNotification);
            if (response) {
                commitSetLogs(context, response.data);
            }
        } catch {
            commitAddNotification(context, { content: 'Logs not found', color: 'error' });
        }
    },
    async getMqttLog(context: MainContext, logId: number) {
        try {
            const loadingNotification = { content: 'Loading log', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.getMqttLog(context.rootState.main.token, logId);
            commitRemoveNotification(context, loadingNotification);
            if (response) {
                commitSetLog(context, response.data);
            }
        } catch {
            commitAddNotification(context, { content: 'Log not found', color: 'error' });
        }
    },
    async deleteLogs(context: MainContext) {
        try {
            await api.deleteMqttLogs(context.rootState.main.token);
            commitAddNotification(context, { content: 'All logs removed', color: 'success' });
        } catch {
            commitAddNotification(context, { content: 'Failed to remove logs', color: 'error' });
        }
    },
    // project
    async getProjects(context: MainContext) {
        try {
            const loadingNotification = { content: 'Loading projects', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.getProjects(context.rootState.main.token);
            commitRemoveNotification(context, loadingNotification);
            if (response) {
                commitSetProjects(context, response.data);
            }
        } catch {
            commitAddNotification(context, { content: 'Projects not found', color: 'error' });
        }
    },
    async getProject(context: MainContext, projectId: number) {
        const loadingNotification = { content: 'Loading project', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = await api.getProject(context.rootState.main.token, projectId);
            commitRemoveNotification(context, loadingNotification);
            if (response) {
                commitSetProject(context, response.data);
            }
        } catch {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Project not found', color: 'error' });
        }
    },
    async createProject(context: MainContext, payload: IProjectCreate) {
        try {
            const loadingNotification = { content: 'Saving project', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.createProject(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Project created', color: 'success' });
        } catch (e) {
            let content = 'Failed to create project';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async createQrUser(context: MainContext, payload: {projectId: any, data: IQrUserCreate}) {
        try {
            const loadingNotification = { content: 'Saving QR code', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.createQrUser(context.rootState.main.token, payload.projectId, payload.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'QR code created', color: 'success' });
        } catch (e) {
            let content = 'Failed to create QR';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async getQrUser(context: MainContext, userId: number) {
        try {
            const response = await api.getUser(context.rootState.main.token, userId);
            if (response) {
                commitSetQrUser(context, response.data);
            }
        } catch (e) {
            let content = 'User not found';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async editQrUser(context: MainContext, payload: {projectId, data: IQrUserUpdate}) {
        try {
            const loadingNotification = { content: 'Saving changes', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.editeQrUser(context.rootState.main.token, payload.projectId, payload.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'QR code updated', color: 'success' });
        } catch (e) {
            let content = 'Failed to update QR';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async updateProject(context: MainContext, payload: {projectId: number, project: IProjectCreate}) {
        try {
            const loadingNotification = { content: 'Saving changes', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.updateProject(context.rootState.main.token, payload.projectId, payload.project);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Project updated', color: 'success' });
        } catch (e) {
            let content = 'Failed to create project';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async deleteProject(context: MainContext, projectId: number) {
        try {
            await api.deleteProject(context.rootState.main.token, projectId);
            commitAddNotification(context, { content: 'Project deleted', color: 'success' });
        } catch {
            commitAddNotification(context, { content: 'Failed to delete project', color: 'error' });
        }
    },
    async assignProjectForUsers(context: MainContext, payload: {projectId: number, data: never[]}) {
        try {
            await api.assignProjectForUsers(payload.data, context.rootState.main.token, payload.projectId);
        } catch {
            commitAddNotification(context, { content: 'Failed to assign project', color: 'error' });
        }
    },
    async unassignProjectForUsers(context: MainContext, payload: {projectId: number, data: never[]}) {
        try {
            await api.unassignProjectForUsers(payload.data, context.rootState.main.token, payload.projectId);
        } catch {
            commitAddNotification(context, { content: 'Failed to assign project', color: 'error' });
        }
    },
    async sendProjectAction(context: MainContext, payload: {projectId: number, action: string}) {
        try {
            const loadingNotification = { content: 'Sending action', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.sendProjectAction(context.rootState.main.token, payload.projectId, payload.action);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Action sent', color: 'success' });
        } catch {
            commitAddNotification(context, { content: 'Failed to send action', color: 'error' });
        }
    },
    // file
    async uploadFile(context: MainContext, formData: FormData) {
        try {
            const loadingNotification = { content: 'Uploading file', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.uploadFile(formData, context.rootState.main.token);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'File Uploaded', color: 'success' });
        } catch (e) {
            let content = 'Failed to upload file';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async updateFile(context: MainContext, payload: { id: number, file: IFileDataUpdate }) {
        try {
            const loadingNotification = { content: 'Saving changes', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.updateFile(context.rootState.main.token, payload.id, payload.file);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'File Updated', color: 'success' });
        } catch (e) {
            let content = 'Failed to update file';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async deleteFile(context: MainContext, fileId: number) {
        try {
            await api.deleteFile(context.rootState.main.token, fileId);
            commitAddNotification(context, { content: 'File deleted', color: 'success' });
        } catch {
            commitAddNotification(context, { content: 'Failed to delete file', color: 'error' });
        }
    },
    async deleteAnalyticHeatmap(context: MainContext, fileId: number) {
        try {
            await api.deleteAnalyticHeatmap(context.rootState.main.token, fileId);
            commitAddNotification(context, { content: 'Analytics deleted', color: 'success' });
        } catch {
            commitAddNotification(context, { content: 'Failed to delete', color: 'error' });
        }
    },
    async deleteAnalyticPeople(context: MainContext, fileId: number) {
        try {
            await api.deleteAnalyticPeople(context.rootState.main.token, fileId);
            commitAddNotification(context, { content: 'Analytics deleted', color: 'success' });
        } catch {
            commitAddNotification(context, { content: 'Failed to delete', color: 'error' });
        }
    },
    async deleteAnalyticPeopleTracking(context: MainContext, fileId: number) {
        try {
            await api.deleteAnalyticPeopleTracking(context.rootState.main.token, fileId);
            commitAddNotification(context, { content: 'Analytics deleted', color: 'success' });
        } catch {
            commitAddNotification(context, { content: 'Failed to delete', color: 'error' });
        }
    },
    async getFile(context: MainContext, fileId: number) {
        const loadingNotification = { content: 'Loading file', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            const response = await api.getFile(context.rootState.main.token, fileId);
            commitRemoveNotification(context, loadingNotification);
            if (response) {
                commitSetFile(context, response.data);
            }
        } catch {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'File not found', color: 'error' });
        }
    },
    async getFilesByProject(context: MainContext, projectId: number) {
        try {
            const response = await api.getFilesByProject(context.rootState.main.token, projectId);
            if (response) {
                commitSetUploadFiles(context, response.data);
            }
        } catch {
            // pass
        }
    },
    async getFilesByBuilding(context: MainContext, buildingId: number) {
        try {
            const response = await api.getFilesByBuilding(context.rootState.main.token, buildingId);
            if (response) {
                commitSetUploadFiles(context, response.data);
            }
        } catch {
            // pass
        }
    },
    // device
    async getDevicesByProject(context: MainContext, projectId: number) {
        try {
            const response = await api.getDevicesByProject(context.rootState.main.token, projectId);
            if (response) {
                commitSetDevices(context, response.data);
            }
        } catch {
            //
        }
    },
    async getDevicesByBuilding(context: MainContext, buildingId: number) {
        try {
            const response = await api.getDevicesByBuilding(context.rootState.main.token, buildingId);
            if (response) {
                commitSetDevices(context, response.data);
            }
        } catch {
            //
        }
    },
    async getDevicesByFile(context: MainContext, fileId: number) {
        try {
            const response = await api.getDevicesByFile(context.rootState.main.token, fileId);
            if (response) {
                commitSetDevices(context, response.data);
            }
        } catch {
            //
        }
    },
    async getDevice(context: MainContext, deviceId: number) {
        try {
            const loadingNotification = { content: 'Loading device', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.getDevice(context.rootState.main.token, deviceId);
            commitRemoveNotification(context, loadingNotification);
            if (response) {
                commitSetDevice(context, response.data);
            }
        } catch {
            commitAddNotification(context, { content: 'Device not found', color: 'error' });
        }
    },
    async updateDevice(context: MainContext, payload: {deviceId: number, data: IDeviceUpdate}) {
        const loadingNotification = { content: 'saving', showProgress: true };
        // commitAddNotification(context, loadingNotification);
        console.log(payload.data)
        try {
            const response = await api.updateDevice(context.rootState.main.token, payload.deviceId, payload.data);
            // if parent is changed
            if (response && payload.data.parent_id !== payload.data.old_parent_id) {
                // delete device in old group
                if (payload.data.old_parent_id) {
                    await api.deleteEntity(
                        context.rootState.main.token,
                        payload.data.old_parent_id as number,
                        {device_ids: [payload.deviceId],
                    });
                }
                // add device to a new group
                if (payload.data.parent_id) {
                    await api.addEntity(
                        context.rootState.main.token,
                        payload.data.parent_id as number,
                        {device_ids: [payload.deviceId],
                    });
                }
            }
            commitRemoveNotification(context, loadingNotification);
            // commitAddNotification(context, { content: 'Device updated', color: 'success' });
        } catch {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Error occured', color: 'error' });
        }
    },
    async deleteDevice(context: MainContext, payload: {deviceId: number, parentId: any}) {
        const loadingNotification = { content: 'deleting', showProgress: true };
        commitAddNotification(context, loadingNotification);
        try {
            await api.updateDevice(context.rootState.main.token, payload.deviceId, {
                channels: [],
                serial_number: null,
            });
            if (payload.parentId) {
                await api.deleteEntity(
                    context.rootState.main.token,
                    payload.parentId as number,
                    {device_ids: [payload.deviceId],
                });
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Device updated', color: 'success' });
        } catch {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Error occured', color: 'error' });
        }
    },
    // group
    async getGroups(context: MainContext, projectId: number) {
        try {
            const response = await api.getGroupsByProject(context.rootState.main.token, projectId);
            if (response) {
                commitSetGroups(context, response.data);
            }
        } catch {
            // pass
        }
    },
    async getGroupsByBuilding(context: MainContext, buildingId: number) {
        try {
            const response = await api.getGroupsByBuilding(context.rootState.main.token, buildingId);
            if (response) {
                commitSetGroups(context, response.data);
            }
        } catch {
            // pass
        }
    },
    async getGroupsByFile(context: MainContext, fileId: number) {
        try {
            const response = await api.getGroupsByFile(context.rootState.main.token, fileId);
            if (response) {
                commitSetGroups(context, response.data);
            }
        } catch {
            // pass
        }
    },
    async getGroup(context: MainContext, groupId: number) {
        try {
            const response = await api.getGroup(context.rootState.main.token, groupId);
            if (response) {
                commitSetGroup(context, response.data);
            }
        } catch {
            // pass
        }
    },
    async updateActiveScene(context: MainContext, payload: {groupId: number, sceneId: any}) {
        try {
           const response = await api.updateActiveScene(context.rootState.main.token, payload.groupId, payload.sceneId);
           if (response) {
            commitSetGroup(context, response.data);
           }
        } catch {
            //
        }
    },
    async updateGroup(context: MainContext, payload: {groupId: number, data: IGroupUpdate}) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.updateGroup(context.rootState.main.token, payload.groupId, payload.data);
            if (payload.data.parent_id !== payload.data.old_parent_id){
                if (!payload.data.parent_id) {
                    if (payload.data.old_parent_id){
                        await api.deleteEntity(
                            context.rootState.main.token,
                            payload.data.old_parent_id as number,
                            {group_ids: [payload.groupId]});
                    }
                } else {
                    if (payload.data.old_parent_id){
                        await api.deleteEntity(
                            context.rootState.main.token,
                            payload.data.old_parent_id as number,
                            {group_ids: [payload.groupId]});
                    }
                    await api.addEntity(
                        context.rootState.main.token,
                        payload.data.parent_id as number,
                        {group_ids: [payload.groupId]});
                }
            }
            if (response) {
                commitSetGroup(context, response.data);
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Group updated', color: 'success' });
        } catch (e) {
            let content = 'Failed to update group';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async createGroup(context: MainContext, payload: IGroupCreate) {
        try {
            const loadingNotification = { content: 'saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.createGroup(context.rootState.main.token, payload);
            if (response && payload.parent_id) {
                await api.addEntity(context.rootState.main.token, payload.parent_id, {group_ids: [response.data.id]});
            }
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Group created', color: 'success' });
        } catch (e) {
            let content = 'Failed to create group';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async moveGroup(context: MainContext, payload: {groupId: number, data: IGroupUpdate}) {
        try {
            await api.moveGroup(context.rootState.main.token, payload.groupId, payload.data);
        } catch (e) {
            //
        }
    },
    async addEntity(context: MainContext, payload: {groupId: number, data: IGroupUpdate}) {
        try {
            await api.addEntity(context.rootState.main.token, payload.groupId, payload.data);
        } catch (e) {
            //
        }
    },
    async deleteGroup(context: MainContext, groupId: number) {
        try {
            await api.deleteGroup(context.rootState.main.token, groupId);
            commitAddNotification(context, { content: 'Group deleted', color: 'success' });
        } catch {
            commitAddNotification(context, { content: 'Failed to delete group', color: 'error' });
        }
    },
    async assignGroupsForUser(context: MainContext, payload: {userId: number, data: never[]}) {
        const loadingNotification = { content: 'Saving', showProgress: true };
        try {
            commitAddNotification(context, loadingNotification);
            await api.assignGroupsForUser(payload.data, context.rootState.main.token, payload.userId);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, {content: 'Groups assigned', color: 'success' });
        } catch {
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Failed to assign groups', color: 'error' });
        }
    },
    async unassignGroupsForUser(context: MainContext, payload: {userId: number, data: never[]}) {
        const loadingNotification = { content: 'Saving', showProgress: true };
        try {
            await api.unassignGroupsForUser(payload.data, context.rootState.main.token, payload.userId);
        } catch {
            // pass
        }
    },
    async getAssignedGroupsForUser(context: MainContext, payload: {userId: number, projectId: number}) {

        try {
            const response = await api.getAssignedGroupsForUser(
                context.rootState.main.token,
                payload.userId,
                payload.projectId,
            );
            if (response) {
                commitSetAssignedGroups(context, response.data);
            }
        } catch {
            // pass
        }
    },
    // scenes
    async getScenes(context: MainContext, groupId: number) {
        try {
            const response = await api.getScenesByGroup(context.rootState.main.token, groupId);
            if (response) {
                commitSetScenes(context, response.data);
            }
        } catch {
            // pass
        }
    },
    async getScene(context: MainContext, sceneId: number) {
        try {
            const response = await api.getScene(context.rootState.main.token, sceneId);
            if (response) {
                commitSetScene(context, response.data);
            }
        } catch {
            // pass
        }
    },
    async deleteScene(context: MainContext, sceneId: number) {
        try {
            await api.deleteScene(context.rootState.main.token, sceneId);
            commitAddNotification(context, { content: 'Scene deleted', color: 'success' });
        } catch {
            // pass
            commitAddNotification(context, { content: 'Failed to delete scene', color: 'error' });
        }
    },
    async createScene(context: MainContext, data: ISceneCreate) {
        try {
            const loadingNotification = { content: 'Saving scene', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.createScene(context.rootState.main.token, data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Scene created', color: 'success' });
        } catch (e) {
            let content = 'Failed to create scene';
            if (e.response) {
                content = `${content} - Detail: ${e.response.data.detail}`;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async updateScene(context: MainContext, payload: {sceneId: number, data: ISceneUpdate}) {
        try {
            const loadingNotification = { content: 'Saving changes', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.updateScene(context.rootState.main.token, payload.sceneId, payload.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Scene updated', color: 'success' });
        } catch (e) {
            let content = 'Failed to update scene';
            if (e.response) {
                content = `${content} - Detail: ${e.response.data.detail}`;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    // event
    async createEvent(context: MainContext, payload: IEventCreate) {
        try {
            const loadingNotification = { content: 'Saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.createEvent(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Event created', color: 'success' });
        } catch (e) {
            let content = 'Failed to create event';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async getEvents(context: MainContext, projectId: number) {
        try {
            const response = await api.getEvents(context.rootState.main.token, projectId);
            if (response) {
                commitSetEvents(context, response.data);
            }
        } catch (e) {
            // pass
        }
    },
    async getEvent(context: MainContext, eventId: number) {
        try {
            const response = await api.getEvent(context.rootState.main.token, eventId);
            if (response) {
                commitSetEvent(context, response.data);
            }
        } catch (e) {
            // pass
        }
    },
    async updateEvent(context: MainContext, payload: {eventId: number, data: IEventUpdate}) {
        try {
            const loadingNotification = { content: 'Saving changes', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.updateEvent(context.rootState.main.token, payload.eventId, payload.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Event updated', color: 'success' });
        } catch (e) {
            let content = 'Failed to update event';
            if (e.response) {
                content = `${content} - Detail: ${e.response.data.detail}`;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async deleteEvent(context: MainContext, eventId: number) {
        try {
            await api.deleteEvent(context.rootState.main.token, eventId);
            commitAddNotification(context, { content: 'Event deleted', color: 'success' });
        } catch {
            // pass
            commitAddNotification(context, { content: 'Failed to delete event', color: 'error' });
        }
    },
    // handlers
    async createHandler(context: MainContext, payload: IHandlerCreate) {
        try {
            const loadingNotification = { content: 'Saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.createHandler(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Handler created', color: 'success' });
        } catch (e) {
            let content = 'Failed to create handler';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async getHandlers(context: MainContext, eventId: number) {
        try {
            const response = await api.getHandlers(context.rootState.main.token, eventId);
            if (response) {
                commitSetHandlers(context, response.data);
            }
        } catch (e) {
            // pass
        }
    },
    async getHandler(context: MainContext, handlerId: number) {
        try {
            const response = await api.getHandler(context.rootState.main.token, handlerId);
            if (response) {
                commitSetHandler(context, response.data);
            }
        } catch (e) {
            // pass
        }
    },
    async deleteHandler(context: MainContext, handlerId: number) {
        try {
            await api.deleteHandler(context.rootState.main.token, handlerId);
            commitAddNotification(context, { content: 'Handler deleted', color: 'success' });
        } catch {
            // pass
            commitAddNotification(context, { content: 'Failed to delete handler', color: 'error' });
        }
    },
    async updateHandler(context: MainContext, payload: {handlerId: number, data: IHandlerUpdate}) {
        try {
            const loadingNotification = { content: 'Saving changes', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.updateHandler(context.rootState.main.token, payload.handlerId, payload.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Handler updated', color: 'success' });
        } catch (e) {
            let content = 'Failed to update handler';
            if (e.response) {
                content = `${content} - Detail: ${e.response.data.detail}`;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    // buildings
    async getBuildings(context: MainContext, projectId: number) {
        try {
            const response = await api.getBuildings(context.rootState.main.token, projectId);
            if (response) {
                commitSetBuildings(context, response.data);
            }
        } catch (e) {
            // pass
        }
    },
    async createBuilding(context: MainContext, payload: IBuildingCreate) {
        try {
            const loadingNotification = { content: 'Saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.createBuilding(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Building created', color: 'success' });
        } catch (e) {
            let content = 'Failed to create building';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async getBuilding(context: MainContext, buildingId: number) {
        try {
            const response = await api.getBuilding(context.rootState.main.token, buildingId);
            if (response) {
                commitSetBuilding(context, response.data);
            }
        } catch (e) {
            // pass
        }
    },
    async updateBuilding(context: MainContext, payload: {buildingId: number, data: IBuildingCreate}) {
        try {
            const loadingNotification = { content: 'Saving changes', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.updateBuilding(context.rootState.main.token, payload.buildingId, payload.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Building updated', color: 'success' });
        } catch (e) {
            let content = 'Failed to update building';
            if (e.response) {
                content = `${content} - Detail: ${e.response.data.detail}`;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async deleteBuilding(context: MainContext, buildingId: number) {
        try {
            await api.deleteBuilding(context.rootState.main.token, buildingId);
            commitAddNotification(context, { content: 'Building deleted', color: 'success' });
        } catch {
            // pass
            commitAddNotification(context, { content: 'Failed to delete building', color: 'error' });
        }
    },
    // floor area
    async getFloorAreas(context: MainContext, fileId: number) {
        try {
            const response = await api.getFloorplanAreas(context.rootState.main.token, fileId);
            if (response) {
                commitSetFloorAreas(context, response.data);
            }
        } catch (e) {
            // pass
        }
    },
    async createFloorArea(context: MainContext, payload: IFloorplanAreaCreate) {
        try {
            const loadingNotification = { content: 'Saving', showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = await api.createFloorplanArea(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Floor area created', color: 'success' });
        } catch (e) {
            let content = 'Failed to create floor area';
            if (e.response) {
                content = e.response.data.detail;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async updateFloorArea(context: MainContext, payload: {floorAreaId: number, data: IFloorplanAreaUpdate}) {
        try {
            const loadingNotification = { content: 'Saving changes', showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.updateFloorplanArea(context.rootState.main.token, payload.floorAreaId, payload.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: 'Floor area updated', color: 'success' });
        } catch (e) {
            let content = 'Failed to update floor area';
            if (e.response) {
                content = `${content} - Detail: ${e.response.data.detail}`;
            }
            commitAddNotification(context, { content, color: 'error' });
        }
    },
    async deleteFloorArea(context: MainContext, floorAreaId: number) {
        try {
            await api.deleteFloorplanArea(context.rootState.main.token, floorAreaId);
            commitAddNotification(context, { content: 'Floor area deleted', color: 'success' });
        } catch {
            // pass
            commitAddNotification(context, { content: 'Failed to delete floor area', color: 'error' });
        }
    },
};

const { dispatch } = getStoreAccessors<MainState | any, State>('');

export const dispatchCheckApiError = dispatch(actions.actionCheckApiError);
export const dispatchCheckLoggedIn = dispatch(actions.actionCheckLoggedIn);
export const dispatchGetUserProfile = dispatch(actions.actionGetUserProfile);
export const dispatchLogIn = dispatch(actions.actionLogIn);
export const dispatchLoginSample = dispatch(actions.actionLogInSample);
export const dispatchLogOut = dispatch(actions.actionLogOut);
export const dispatchUserLogOut = dispatch(actions.actionUserLogOut);
export const dispatchRemoveLogIn = dispatch(actions.actionRemoveLogIn);
export const dispatchRouteLoggedIn = dispatch(actions.actionRouteLoggedIn);
export const dispatchRouteLogOut = dispatch(actions.actionRouteLogOut);
export const dispatchUpdateUserProfile = dispatch(actions.actionUpdateUserProfile);
export const dispatchRemoveNotification = dispatch(actions.removeNotification);
export const dispatchPasswordRecovery = dispatch(actions.passwordRecovery);
export const dispatchResetPassword = dispatch(actions.resetPassword);

// file
export const dispatchUploadFile = dispatch(actions.uploadFile);
export const dispatchUpdateFile = dispatch(actions.updateFile);
export const dispatchDeleteFile = dispatch(actions.deleteFile);
export const dispatchGetFilesByProject = dispatch(actions.getFilesByProject);
export const dispatchGetFile = dispatch(actions.getFile);
export const dispatchGetFilesByBuilding = dispatch(actions.getFilesByBuilding);

// device
export const dispatchGetDevices = dispatch(actions.getDevicesByProject);
export const dispatchGetDevicesByBuilding = dispatch(actions.getDevicesByBuilding);
export const dispatchGetDevicesByFile = dispatch(actions.getDevicesByFile);
export const dispatchGetDevice = dispatch(actions.getDevice);
export const dispatchUpdateDevice = dispatch(actions.updateDevice);
export const dispatchDeleteDevice = dispatch(actions.deleteDevice);

// log
export const dispatchGetLogs = dispatch(actions.getMqttLogs);
export const dispatchGetLog = dispatch(actions.getMqttLog);
export const dispatchDeleteLogs = dispatch(actions.deleteLogs);

// project
export const dispatchGetProjects = dispatch(actions.getProjects);
export const dispatchGetProject = dispatch(actions.getProject);
export const dispatchCreateProject = dispatch(actions.createProject);
export const dispatchUpdateProject = dispatch(actions.updateProject);
export const dispatchDeleteProject = dispatch(actions.deleteProject);
export const dispatchAssignProjectForUsers = dispatch(actions.assignProjectForUsers);
export const dispatchUnassignProjectForUsers = dispatch(actions.unassignProjectForUsers);
export const dispatchSendProjectAction = dispatch(actions.sendProjectAction);

// group
export const dispatchGetGroups = dispatch(actions.getGroups);
export const dispatchGetGroupsByBuilding = dispatch(actions.getGroupsByBuilding);
export const dispatchGetGroupsByFile = dispatch(actions.getGroupsByFile);
export const dispatchGetGroup = dispatch(actions.getGroup);
export const dispatchCreateGroup = dispatch(actions.createGroup);
export const dispatchUpdateGroup = dispatch(actions.updateGroup);
export const dispatchDeleteGroup = dispatch(actions.deleteGroup);
export const dispatchAssignGroupsForUser = dispatch(actions.assignGroupsForUser);
export const dispatchUnassignGroupsForUser = dispatch(actions.unassignGroupsForUser);
export const dispatchGetAssignedGroupForUsers = dispatch(actions.getAssignedGroupsForUser);
export const dispatchMoveGroup = dispatch(actions.moveGroup);
export const dispatchAddEntity = dispatch(actions.addEntity);
export const dispatchUpdateActiceScene = dispatch(actions.updateActiveScene);

// scene
export const dispatchGetScenes = dispatch(actions.getScenes);
export const dispatchGetScene = dispatch(actions.getScene);
export const dispatchCreateScene = dispatch(actions.createScene);
export const dispatchUpdateScene = dispatch(actions.updateScene);
export const dispatchDeleteScene = dispatch(actions.deleteScene);

// qr
export const dispatchCreateQrUser = dispatch(actions.createQrUser);
export const dispatchGetQrUser = dispatch(actions.getQrUser);
export const dispatchUpdateQrUser = dispatch(actions.editQrUser);

// event
export const dispatchCreateEvent = dispatch(actions.createEvent);
export const dispatchGetEvents = dispatch(actions.getEvents);
export const dispatchGetEvent = dispatch(actions.getEvent);
export const dispatchDeleteEvent = dispatch(actions.deleteEvent);
export const dispatchUpdateEvent = dispatch(actions.updateEvent);

// handler
export const dispatchCreateHandler = dispatch(actions.createHandler);
export const dispatchGetHandler = dispatch(actions.getHandler);
export const dispatchGetHandlers = dispatch(actions.getHandlers);
export const dispatchDeleteHandler = dispatch(actions.deleteHandler);
export const dispatchUpdateHandler = dispatch(actions.updateHandler);

// buildings
export const dispatchGetBuildings = dispatch(actions.getBuildings);
export const dispatchGetBuilding = dispatch(actions.getBuilding);
export const dispatchCreateBuilding = dispatch(actions.createBuilding);
export const dispatchDeleteBuilding = dispatch(actions.deleteBuilding);
export const dispatchUpdateBuilding = dispatch(actions.updateBuilding);

// analytics
export const dispatchDeleteAnalyticPeople = dispatch(actions.deleteAnalyticPeople);
export const dispatchDeleteAnalyticHeatmap = dispatch(actions.deleteAnalyticHeatmap);
export const dispatchDeleteAnalyticPeopleTracking = dispatch(actions.deleteAnalyticPeopleTracking);

// floor area
export const dispatchGetFloorAreasByFile = dispatch(actions.getFloorAreas);
export const dispatchCreateFloorArea = dispatch(actions.createFloorArea);
export const dispatchDeleteFloorArea = dispatch(actions.deleteFloorArea);
export const dispatchUpdateFloorArea = dispatch(actions.updateFloorArea);
