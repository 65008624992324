import { MainState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    hasAdminAccess: (state: MainState) => {
        return (
            state.userProfile && state.userProfile.role && 
            state.userProfile.role === 'admin' && state.userProfile.is_active);
    },
    loginError: (state: MainState) => state.logInError,
    loginErrorMessage: (state: MainState) => state.logInErrorMessage,
    dashboardShowDrawer: (state: MainState) => state.dashboardShowDrawer,
    dashboardMiniDrawer: (state: MainState) => state.dashboardMiniDrawer,
    userProfile: (state: MainState) => state.userProfile,
    token: (state: MainState) => state.token,
    isLoggedIn: (state: MainState) => state.isLoggedIn,
    firstNotification: (state: MainState) => state.notifications.length > 0 && state.notifications[0],
    uploadFiles: (state: MainState) => state.files,
    projects: (state: MainState) => state.projects,
    file: (state: MainState) => state.file,
    project: (state: MainState) => state.project,
    mqttLogs: (state: MainState) => state.mqttLogs,
    mqttLog: (state: MainState) => state.mqttLog,
    groups: (state: MainState) => state.groups,
    group: (state: MainState) => state.group,
    scenes: (state: MainState) => state.scenes,
    scene: (state: MainState) => state.scene,
    devices: (state: MainState) => state.devices,
    device: (state: MainState) => state.device,
    assignedGroups: (state: MainState) => state.assignedGroups,
    qrUser: (state: MainState) => state.qrUser,
    events: (state: MainState) => state.events,
    event: (state: MainState) => state.event,
    handler: (state: MainState) => state.handler,
    handlers: (state: MainState) => state.handlers,
    buildings: (state: MainState) => state.buildings,
    building: (state: MainState) => state.building,
    floorArea: (state: MainState) => state.floorArea,
    floorAreas: (state: MainState) => state.floorAreas,
};

const {read} = getStoreAccessors<MainState, State>('');

export const readDashboardMiniDrawer = read(getters.dashboardMiniDrawer);
export const readDashboardShowDrawer = read(getters.dashboardShowDrawer);
export const readHasAdminAccess = read(getters.hasAdminAccess);
export const readIsLoggedIn = read(getters.isLoggedIn);
export const readLoginError = read(getters.loginError);
export const readLoginErrorMessage = read(getters.loginErrorMessage);
export const readToken = read(getters.token);
export const readUserProfile = read(getters.userProfile);
export const readFirstNotification = read(getters.firstNotification);
export const readUploadedFiles = read(getters.uploadFiles);
export const readProjects = read(getters.projects);
export const readFile = read(getters.file);
export const readProject = read(getters.project);
export const readMqttLogs = read(getters.mqttLogs);
export const readMqttLog = read(getters.mqttLog);
export const readGroups = read(getters.groups);
export const readGroup = read(getters.group);
export const readScenes = read(getters.scenes);
export const readScene = read(getters.scene);
export const readDevices = read(getters.devices);
export const readDevice = read(getters.device);
export const readAssignedGroups = read(getters.assignedGroups);
export const readQrUser = read(getters.qrUser);
export const readEvents = read(getters.events);
export const readEvent = read(getters.event);
export const readHandlers = read(getters.handlers);
export const readHandler = read(getters.handler);
export const readBuilding = read(getters.building);
export const readBuildings = read(getters.buildings);
export const readAreas = read(getters.floorAreas);
export const readArea = read(getters.floorArea);
