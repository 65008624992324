import axios from 'axios';
import { apiUrl } from '@/env';
import { 
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IFileDataUpdate,
  IProjectCreate,
  IGroupCreate,
  IGroupUpdate,
  IQrUserCreate,
  IQrUserUpdate,
  ISceneCreate,
  ISceneUpdate,
  IEventCreate,
  IEventUpdate,
  IHandlerCreate,
  IHandlerUpdate,
  IBuildingCreate,
  IFloorplanAreaCreate,
  IFloorplanAreaUpdate,
} from './interfaces';

function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/`, authHeaders(token));
  },
  async getUnassignUsersByProject(token: string, projectId: number) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/projects/${projectId}/unassign_users`, authHeaders(token));
  },
  async getUsersByProject(token: string, projectId: number) {
    return axios.get<IUserProfile[]>(`${apiUrl}/api/v1/users/?project_id=${projectId}`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async getUser(token: string, userId: number) {
    return axios.get(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
  },
  async deleteUser(token: string, userId: number) {
    return axios.delete(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },
  /** File */
  async uploadFile(formData: FormData, token: string) {
    return axios.post(`${apiUrl}/api/v1/files/`, formData, authHeaders(token));
  },
  async getFile(token: string, fileId: number) {
    return axios.get(`${apiUrl}/api/v1/files/${fileId}`, authHeaders(token));
  },
  async getFilesByProject(token: string, projectId: number) {
    return axios.get(`${apiUrl}/api/v1/files?project_id=${projectId}`, authHeaders(token));
  },
  async getFilesByBuilding(token: string, buildingId: number) {
    return axios.get(`${apiUrl}/api/v1/files?building_id=${buildingId}`, authHeaders(token));
  },
  async deleteFile(token: string, fileId: number) {
    return axios.delete(`${apiUrl}/api/v1/files/${fileId}`, authHeaders(token));
  },
  async updateFile(token: string, fileId: number, data: IFileDataUpdate) {
    return axios.put(`${apiUrl}/api/v1/files/${fileId}`, data, authHeaders(token));
  },
  /** Device */
  async getDevice(token: string, deviceId: number) {
    return axios.get(`${apiUrl}/api/v1/devices/${deviceId}`, authHeaders(token));
  },
  async updateDevice(token: string, deviceId: number, data: {}) {
    return axios.put(`${apiUrl}/api/v1/devices/${deviceId}`,  data, authHeaders(token));
  },
  async getDevicesByProject(token: string, projectId: number) {
    return axios.get(`${apiUrl}/api/v1/projects/${projectId}/devices_full`, authHeaders(token));
  },
  async getDevicesByFile(token: string, fileId: number) {
    return axios.get(`${apiUrl}/api/v1/files/${fileId}/devices`, authHeaders(token));
  },
  /** Log */
  async getMqttLogs(token: string) {
    return axios.get(`${apiUrl}/api/v1/logs/`, authHeaders(token));
  },
  async getMqttLog(token: string, logId: number) {
    return axios.get(`${apiUrl}/api/v1/logs/${logId}`, authHeaders(token));
  },
  async deleteMqttLogs(token: string) {
    return axios.delete(`${apiUrl}/api/v1/logs`, authHeaders(token));
  },
  /** Project */
  async getProjects(token: string) {
    return axios.get(`${apiUrl}/api/v1/projects`, authHeaders(token));
  },
  async getProject(token: string, projectId: number) {
    return axios.get(`${apiUrl}/api/v1/projects/${projectId}`, authHeaders(token));
  },
  async createProject(token: string, data: IProjectCreate) {
    return axios.post(`${apiUrl}/api/v1/projects/`, data, authHeaders(token));
  },
  async createQrUser(token: string, projectId, data: IQrUserCreate) {
    return axios.post(`${apiUrl}/api/v1/projects/${projectId}/qr_user`, data, authHeaders(token));
  },
  async editeQrUser(token: string, projectId, data: IQrUserUpdate) {
    return axios.put(`${apiUrl}/api/v1/projects/${projectId}/qr_user`, data, authHeaders(token));
  },
  async updateProject(token: string, projectId: number, data: IProjectCreate) {
    return axios.put(`${apiUrl}/api/v1/projects/${projectId}`, data, authHeaders(token));
  },
  async deleteProject(token: string, projectId: number) {
    return axios.delete(`${apiUrl}/api/v1/projects/${projectId}`, authHeaders(token));
  },
  async sendProjectAction(token: string, projectId, action) {
    return axios.post(`${apiUrl}/api/v1/projects/${projectId}/actions`,  {'action': action}, authHeaders(token));
  },
  async assignProjectForUsers(data: never[], token: string, projectId: number) {
    return axios.post(`${apiUrl}/api/v1/projects/${projectId}/assign_users`, {'user_ids': data}, authHeaders(token));
  },
  async unassignProjectForUsers(data: never[], token: string, projectId: number) {
    return axios.post(`${apiUrl}/api/v1/projects/${projectId}/unassign_users`, {'user_ids': data}, authHeaders(token));
  },
  /** Group */
  async getGroupsByProject(token: string, projectId: number) {
    return axios.get(`${apiUrl}/api/v1/groups?project_id=${projectId}`, authHeaders(token));
  },
  async getGroupsByBuilding(token: string, buildingId: number) {
    return axios.get(`${apiUrl}/api/v1/groups?building_id=${buildingId}`, authHeaders(token));
  },
  async getGroupsByFile(token: string, fileId: number) {
    return axios.get(`${apiUrl}/api/v1/groups?file_id=${fileId}`, authHeaders(token));
  },
  async getGroup(token: string, groupId: number) {
    return axios.get(`${apiUrl}/api/v1/groups/${groupId}`, authHeaders(token));
  },
  async createGroup(token: string, data: IGroupCreate) {
    return axios.post(`${apiUrl}/api/v1/groups/`, data, authHeaders(token));
  },
  async updateGroup(token: string, groupId: number, data: IGroupUpdate) {
    return axios.put(`${apiUrl}/api/v1/groups/${groupId}`, data, authHeaders(token));
  },
  async updateActiveScene(token: string, groupId: number, sceneId: any) {
    return axios.put(`${apiUrl}/api/v1/groups/${groupId}`, {scene_id: sceneId}, authHeaders(token));
  },
  async moveGroup(token: string, groupId: number, data: IGroupUpdate) {
    return axios.post(`${apiUrl}/api/v1/groups/${groupId}/move`, data, authHeaders(token));
  },
  async addEntity(token: string, groupId: number, data: {}) {
    return axios.post(`${apiUrl}/api/v1/groups/${groupId}`, data, authHeaders(token));
  },
  async deleteEntity(token: string, groupId: number, data: {}) {
    return axios.delete(`${apiUrl}/api/v1/groups/${groupId}/entities`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data,
    });
  },
  async deleteGroup(token: string, groupId: number) {
    return axios.delete(`${apiUrl}/api/v1/groups/${groupId}`, authHeaders(token));
  },
  async assignGroupsForUser(data: never[], token: string, userId: number) {
    return axios.post(`${apiUrl}/api/v1/users/${userId}/assign_groups`, {'group_ids': data}, authHeaders(token));
  },
  async unassignGroupsForUser(data: never[], token: string, userId: number) {
    return axios.post(`${apiUrl}/api/v1/users/${userId}/unassign_groups`, {'group_ids': data}, authHeaders(token));
  },
  async getAssignedGroupsForUser(token: string, userId: number, project_id: number) {
    return axios.get(`${apiUrl}/api/v1/users/${userId}/assigned_group?project_id=${project_id}`, authHeaders(token));
  },
  /** Scene */
  async getScenesByGroup(token: string, groupId: number) {
    return axios.get(`${apiUrl}/api/v1/scenes?group_id=${groupId}`, authHeaders(token));
  },
  async getScene(token: string, sceneId: number) {
    return axios.get(`${apiUrl}/api/v1/scenes/${sceneId}`, authHeaders(token));
  },
  async updateScene(token: string, sceneId: number, data: ISceneUpdate) {
    return axios.put(`${apiUrl}/api/v1/scenes/${sceneId}`, data, authHeaders(token));
  },
  async createScene(token: string, data: ISceneCreate) {
    return axios.post(`${apiUrl}/api/v1/scenes/`, data, authHeaders(token));
  },
  async deleteScene(token: string, sceneId: number) {
    return axios.delete(`${apiUrl}/api/v1/scenes/${sceneId}`, authHeaders(token));
  },
  /** Event */
  async createEvent(token: string, data: IEventCreate) {
    return axios.post(`${apiUrl}/api/v1/events/`, data, authHeaders(token));
  },
  async updateEvent(token: string, eventId: number, data: IEventUpdate) {
    return axios.put(`${apiUrl}/api/v1/events/${eventId}`, data, authHeaders(token));
  },
  async deleteEvent(token: string, eventId: number) {
    return axios.delete(`${apiUrl}/api/v1/events/${eventId}`, authHeaders(token));
  },
  async getEvents(token: string, projectId: number) {
    return axios.get(`${apiUrl}/api/v1/events?project_id=${projectId}`, authHeaders(token));
  },
  async getEvent(token: string, eventId: number) {
    return axios.get(`${apiUrl}/api/v1/events/${eventId}`, authHeaders(token));
  },
  /** Handlers */
  async createHandler(token: string, data: IHandlerCreate) {
    return axios.post(`${apiUrl}/api/v1/handlers/`, data, authHeaders(token));
  },
  async updateHandler(token: string, handerId: number, data: IHandlerUpdate) {
    return axios.put(`${apiUrl}/api/v1/handlers/${handerId}`, data, authHeaders(token));
  },
  async deleteHandler(token: string, handerId: number) {
    return axios.delete(`${apiUrl}/api/v1/handlers/${handerId}`, authHeaders(token));
  },
  async getHandlers(token: string, eventId: number) {
    return axios.get(`${apiUrl}/api/v1/handlers?event_id=${eventId}`, authHeaders(token));
  },
  async getHandler(token: string, handerId: number) {
    return axios.get(`${apiUrl}/api/v1/handlers/${handerId}`, authHeaders(token));
  },
  /** Building */
  async createBuilding(token: string, data: IBuildingCreate) {
    return axios.post(`${apiUrl}/api/v1/buildings/`, data, authHeaders(token));
  },
  async updateBuilding(token: string, buildingId: number, data: IBuildingCreate) {
    return axios.put(`${apiUrl}/api/v1/buildings/${buildingId}`, data, authHeaders(token));
  },
  async deleteBuilding(token: string, buildingId: number) {
    return axios.delete(`${apiUrl}/api/v1/buildings/${buildingId}`, authHeaders(token));
  },
  async getBuildings(token: string, projectId: number) {
    return axios.get(`${apiUrl}/api/v1/buildings?project_id=${projectId}`, authHeaders(token));
  },
  async getBuilding(token: string, buildingId: number) {
    return axios.get(`${apiUrl}/api/v1/buildings/${buildingId}`, authHeaders(token));
  },
  async getDevicesByBuilding(token: string, buildingId: number) {
    return axios.get(`${apiUrl}/api/v1/buildings/${buildingId}/devices_full`, authHeaders(token));
  },
  /** Analytic */
  async deleteAnalyticHeatmap(token: string, fileId: number) {
    return axios.delete(`${apiUrl}/api/v1/files/${fileId}/analytics_areas`, authHeaders(token));
  },
  async deleteAnalyticPeople(token: string, fileId: number) {
    return axios.delete(`${apiUrl}/api/v1/files/${fileId}/analytics_people`, authHeaders(token));
  },
  async deleteAnalyticPeopleTracking(token: string, fileId: number) {
    return axios.delete(`${apiUrl}/api/v1/files/${fileId}/analytics_people_tracking`, authHeaders(token));
  },

  /** Floorplan Area */
  async createFloorplanArea(token: string, data: IFloorplanAreaCreate) {
    return axios.post(`${apiUrl}/api/v1/floor_areas/`, data, authHeaders(token));
  },
  async updateFloorplanArea(token: string, floorplanAreaId: number, data: IFloorplanAreaUpdate) {
    return axios.put(`${apiUrl}/api/v1/floor_areas/${floorplanAreaId}`, data, authHeaders(token));
  },
  async deleteFloorplanArea(token: string, floorplanAreaId: number) {
    return axios.delete(`${apiUrl}/api/v1/floor_areas/${floorplanAreaId}`, authHeaders(token));
  },
  async getFloorplanAreas(token: string, floorplanId: number) {
    return axios.get(`${apiUrl}/api/v1/files/${floorplanId}/areas`, authHeaders(token));
  },
};
